/* we can use here all scss vars from bulma.scss 

mobile: up to 768px
tablet: from 769px
desktop: from 1024px
widescreen: from 1216px
fullhd: from 1408px

*/

#wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
}

a.button {
  text-decoration: none !important;
}

.page-logo {
  position:absolute;
  height:150px
}

#main-navbar {
  margin-left:300px;
}

@media screen and (max-width: 1023px) {
  .page-logo img {
    max-height: 6.5rem;
  }
  #main-navbar {
    margin-left:0;
  }
}

.is-size-0 {
  font-size: $size-0;
}

@media screen and (max-width: 769px) {
  .is-size-0 {
    font-size: $size-1;
  }
}

@media screen and (max-width: 512px) {
  .is-size-0 {
    font-size: $size-2;
  }
}

.is-transparent {
  background-color: transparent !important;
}

.has-hoverfx {
  opacity: 1;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.has-hoverfx:hover {
  opacity: 0.8;
}

.has-textshadow {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

path {
  fill: transparent;
}

.has-white-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 0) 66%
  );
}

.has-background-lightstripes {
  background-image:url(../img/stripes.png);
  border-top:5px solid white;
}

.has-background-whitestripes {
  background-image:url(../img/stripeswhite.png);
  border-top:5px solid white;
}

.has-background-image {
  background-size: cover;
  background-position: center center;
}

.has-dark-opacity-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navbar-item img {
  max-height: 100% !important;
}

.section-header {
  margin-bottom: 2rem;
}

a.navbar-item {
  text-transform: uppercase;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

#get_member {
  position: absolute;
  width: 100px;
  top: -30px;
  right: 0;
  -webkit-transition: top 0.2s;
  -moz-transition: top 0.2s;
  -o-transition: top 0.2s;
  transition: top 0.2s;
}

#get_member:hover {
  top: 0;
}

@media screen and (max-width: 1023px) {
  #get_member {
    display:none;
  }
}

.zitat {
  position: relative;
  background: url(../img/stripeswhite.png);
  padding: 2%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 0 2px #999;
  -moz-box-shadow: 0 0 2px #999;
  -webkit-box-shadow: 0 0 2px #999;
}

.arrow {
  display: block;
  position: absolute;
  top: 1.5em;
  left: -12px;
  width: 21px;
  height: 21px;
  background: url(../img/arrow.png);
}

blockquote {
  font-size: 1em;
  margin: 1em 0;
  line-height: 1.5em;
  color: #666;
  font-style: italic;
}
