.wysiwyg-text {
  display: block;
}

.content a {
  color: $primary;
  text-decoration: underline;
}

.content h1 {
  font-size: $size-1;
  color: $primary;
  margin-bottom: .5rem;
  font-weight:normal;
}

.content h2 {
  font-size: $size-2;
  color: $primary;
  margin-bottom: .5rem;
  font-weight:normal;
}

.content h3 {
  font-size: $size-3;
  margin: .5rem 0;
  font-weight:normal;
}

.content h4 {
  font-size: $size-4;
  margin-bottom: .5rem 0;
  font-weight:normal;
}

@media screen and (max-width: 768px) {
  .content h1 {
    font-size: $size-2;
  }
  .content h2 {
    font-size: $size-3;
  }
}

@media screen and (max-width: 512px) {
  .content h1 {
    font-size: $size-3;
  }
  .content h2 {
    font-size: $size-3;
  }
}