.g360_ytembed {
  width: 100%;
  background-color: #222;
}

.g360_ytembed img {
  position: relative;
  width: 100% !important;
  height: auto !important;
}

.g360_dsgvoinfo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.g360_dsgvoinfo-wrap {
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  max-width: 90%;
}

@media screen and (max-width: 500px) {
  .g360_dsgvoinfo-wrap {
    padding: 10px 0;
    font-size: 0.9em;
  }

  .g360_ytplayicon {
    width: 40px;
    height: auto;
  }

  .g360_vidtitle {
    font-size: 1em;
  }
}

.g360_ytemb_legalinfo {
  display: inline-block;
  width: 100%;
  font-size: 0.7em;
  color: white;
  padding: 5px;
}

.g360_yt_disallow,
.g360_vi_disallow {
  text-decoration: underline;
  cursor: pointer;
}

/* Video */
.g360_videocontainer {
  position: relative;
  padding: 0 0 56.25% 0;
  height: 0;
  margin: 0;
  overflow: hidden;
  z-index: 0;
}

.g360_videocontainer iframe,
.g360_videocontainer object,
.g360_videocontainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
