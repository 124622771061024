@charset "utf-8";

/* archivo-narrow-regular - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/archivo-narrow-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/archivo-narrow-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/archivo-narrow-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/archivo-narrow-v21-latin-regular.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-700 - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/archivo-narrow-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/archivo-narrow-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/archivo-narrow-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/archivo-narrow-v21-latin-700.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-italic - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/archivo-narrow-v21-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/archivo-narrow-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/archivo-narrow-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/archivo-narrow-v21-latin-italic.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-700italic - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/archivo-narrow-v21-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/archivo-narrow-v21-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/archivo-narrow-v21-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/archivo-narrow-v21-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/archivo-narrow-v21-latin-700italic.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
// Set your brand colors
$scheme-main: #ffffff;
$scheme-invert: #000000;
$widescreen-enabled: true; // no widescreen support
$fullhd-enabled: true; // fullHD support

// Update Bulma's global variables
$family-sans-serif: "Archivo Narrow", sans-serif;
$primary: #18749a;
$secondary: #1FBBEF;
$primary-dark: darken($primary, 10%);
// https://falkus.co/2017/05/using-lighten-and-darken-in-sass/
$primary-hover: darken($primary, 2%);
$white: #ffffff;
$black: #000000;
$dark: #191919;
$gray: #888888;
$light: #f3f3f3;
$link: $primary-dark;
$button-focus-border-color: $primary;
$button-border-width: 1px;
$link-active: $primary-dark;
$weight-bold: 700;

// Update some of Bulma's component variables

// Body
$body-font-size: 1.2em;

// Font sizes
$size-0: 5rem;
$size-1: 2.3rem;
$size-2: 1.5rem;
$size-3: 1.25rem;
$size-4: 1.25rem;
$size-5: 1.25rem;
$size-6: 1.2rem;
$size-7: 1rem;

// Navbar
$navbar-background-color: #D0E1EB;
$navbar-box-shadow-size: 0 0 0 0;
$navbar-box-shadow-color: $dark;
$navbar-height: 5rem;
$navbar-item-color: $scheme-invert;
$navbar-item-hover-color: $primary;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $primary;
$navbar-item-active-background-color: $scheme-main;
$navbar-burger-color: $navbar-item-color;
$navbar-dropdown-background-color: $scheme-main;
$navbar-dropdown-border-top: 2px solid $white;
$navbar-dropdown-offset: -4px;
$navbar-dropdown-arrow: $navbar-item-color;

.navbar-container {
  background-image:url(../img/stripes.png);
  border-bottom:5px solid white;
  height:115px;
}

@media screen and (max-width: 1023px) {
  .navbar-container {
    background-image:url(../img/stripes.png);
    border-bottom:5px solid white;
    height:auto;
  }
}

a.navbar-item {
  position:relative;
  text-transform:none !important;
  font-size:1em !important;
}


a.navbar-item.active::before {
  content:'';
  position:absolute;
  width:100%;
  height:5px;
  left:0;
  bottom:20px;
  background:$primary;
}


// Card
$card-background-color: $white;
$card-header-background-color: $primary;
$card-header-color: $scheme-main;
$card-header-padding: 0.75rem 1rem;
$card-shadow: 0 0.5em 1em -0.125em rgba($dark, 0.1),
  0 0px 0 1px rgba($dark, 0.02);
$card-header-shadow: 0 0.125em 0.25em rgba($scheme-invert, 0.1);
$card-header-weight: $weight-bold;
$card-radius: 0.25rem;

// Footer
$footer-background-color: #275064;
$footer-color: white;
$footer-padding: 3rem 0;

.footer {
  background-image:url(../img/stripesdark.png);
  border-top:5px solid white;
  color:white !important;
  font-size:.8em;
}

.footer strong,
.footer a {
  color:white !important;
  text-decoration:none !important;
}

// Import only what you need from Bulma
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/elements/_all.sass";
@import "../../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../../node_modules/bulma/sass/elements/container.sass";
@import "../../../node_modules/bulma/sass/elements/title.sass";
@import "../../../node_modules/bulma/sass/form/_all.sass";
@import "../../../node_modules/bulma/sass/components/navbar.sass";
@import "../../../node_modules/bulma/sass/components/card.sass";
@import "../../../node_modules/bulma/sass/components/menu.sass";
@import "../../../node_modules/bulma/sass/layout/hero.sass";
@import "../../../node_modules/bulma/sass/layout/section.sass";
@import "../../../node_modules/bulma/sass/layout/footer.sass";
@import "../../../node_modules/bulma/sass/grid/columns.sass";

// Import all other used scss
@import "./extensions/_all";
